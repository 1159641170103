import pp from "../assets/books/precolonialBlackAfrica.jpg"
export  const x =  [
    {
        title:"Precolonial Black Africa",
        author:"Cheikh Anta Diop",
        img: pp,
        link: "https://drive.google.com/file/d/1qgEQjD8ZRZaY73DSbwOXe-8lcPQM8Gmp/view?usp=sharing"
    }
]

export const homeUrl = "https://magmukendi.github.io"



export const SEWork = [
    {
        company: "Naver Z (ZEPETO)",
        position:'World Platform - Software Engineer Intern',
        location: 'Seoul, South Korea',
        date: 'July 2023 - September 2023',
        responsibilities: [
            "Added the QuickChat module to the Zepeto Module Importer",
            "Added new features to the Gesture Module",
            "Conducted R&D for the development of new SDKs"
        ]
    },
    {
        company: "Naver Z (ZEPETO)",
        position:'Tech Evangelist Intern',
        location: 'Seoul, South Korea',
        date: 'April 2023 - July 2023',
        responsibilities: [
            "Provided technical support to partner companies while building their Zepeto worlds",
            "Built different sample projects for Zepeto Beginner developpers.",
        ]
    },
    {
        company: "Schaeffler Korea",
        position:'Software Engineer Intern',
        location: 'Seoul, South Korea',
        date: 'December 2022 - March 2023',
        responsibilities: [
            "Built the Schaeffler Seoul Office in the Metaverse using the Zepeto World SDK"
        ]
    },
    {
        company: "WEPO RDC",
        position:'SoftwareEngineer Developer & Product Owner',
        location: 'Remote - Part time',
        date: 'April 2021 - Present',
        responsibilities: [
            "Wrote functionalities requirement documents and guides to help smooth the development process.",
            "Illustrated design ideas using storyboards, process flows and wireframing for the multiple version of the application prototype.",
            "Conducted user research interview with up to 30 different users.",
            "Implemented, iterated and maintained the web application and the different components of the mobile app.",
            "Collaborated with the team developers by conducting code reviews and sprints."
        ]
    },

]

export const LeaderExp = [
    {
        company: "Global Student Association (GSA)",
        companyLocation: 'Hankuk University of Foreign Studies',
        position:'President',
        location: 'Seoul, South Korea',
        date: 'March 2020 - March 2021',
        responsibilities: [
            "Increased the Global Students Association scholarship budget by 100 percent. ",
            "Created a system that helped 100+ international students'' find part-time jobs, free training programs and extra-curricular activities while attending university.",
            "Negotiated for a higher quota of international students participation in the HUFS x HUFS Mentoring program organized by the HUFS Alumni Association.",
            "Received a scholarship from the Ministry of Education in recognition of the outstanding support provided to international students during the COVID-19 pandemic."
        ]
    },
    {
        company: "Council on International Students Affairs",
        companyLocation: 'Hankuk University of Foreign Studies',
        position:'International Students’ Representative',
        location: 'Seoul, South Korea',
        date: 'September 2020 - December 2020',
        responsibilities: [
            "Spearheaded the campaign to raise awareness about the 12 main challenges and needs of the 1500+ regular international students at HUFS.",
            "Consolidated the partnership with the HUFS General Student Council, the Dean of International Affairs, the Campus English newspaper, and International Students Organization from other Universities.",
            "Led the process of solving international students’ challenges such as the availability of classes conducted in English, the access to accurate information about University life, and the participation to extracurricular activities on Campus.",
        ]
    },
]
export const marketingWork = [
    {
        company: "WEPO RDC",
        position:'CEO',
        location: 'Seoul, South Korea',
        date: 'April 2022 - Present',
        responsibilities: [
            "Developed sales strategy to acquire the first 30 customers generating a 180% increase in sales.", 
            "Developed a digital marketing to grow the social media presence.",
            "Improved the business operations that cut half the risk of supplying product to commissioner."
        ]
    },
    {
        company: "WEPO RDC",
        position:'Marketing Manager',
        location: 'Seoul, South Korea',
        date: 'April 2021 - May 2022',
        responsibilities: [
            "Conducted market research by interviewing over 100 users to identify the users pain points.",
            "Analyzed the users online behaviors in a communities of about 1.9 millions people to define the appropriate target market.",
            "Conducted Alpha & Beta Test with  over 20 users for collecting user’s feedback."
        ]
    },
    {
        company: "243 Studio",
        position:'Brand Strategist',
        location: 'Remote - Freelance',
        date: 'March 2020 - Present',
        responsibilities: [
            "Designed and delivered 50+ graphic contents for various media including logos, catalogs, flyers, posters, t-shirts, illustrations, layouts and photos and videos, which improved the employer’s brand presence across social media.",
            
        ]
    },
    {
        company: "2022 UPF World Summit  co-hosted by the Royal Government of Cambodia",
        position:'Assistant Program Manager',
        location: 'Seoul, South Korea',
        date: 'February 2022',
        responsibilities: [
            "Collected and organized over 80 speeches and addresses from heads of state, government representatives and other leaders attending the summit.",
            "Helped schedule and monitor 8 ThinkTank sessions for online and offline participants from over 160 nations.",
            "Provided direct assistance to the translation team, the media team and the protocol team in fulfilling their responsibilities.",
            "Condensed materials that summed up the different speeches and addresses during the summit."
        ]
    },
    {
        company: "GoldOcean Communication",
        position:'Marketing Assistant',
        location: 'Seoul, South Korea',
        date: 'March 2021 - September 2021',
        responsibilities: [
            "Managed over 70+ SNS Campaigns to increase the consumer awareness about the brand. ",
            "Searched, identified and qualified prospective influencers following client alignment.",
            "Built a network of over 1000+ influencers from in Malaysia, France, USA, Singapore, and Philippines to facilitate influencer marketing Campaign.",
            "Developed and implemented an outreach strategy for achieving daily tasks 2 times faster."
        ]
    },
    {
        company: "Global Student Association (GSA)",
        companyLocation: 'Hankuk University of Foreign Studies',
        position:'President',
        location: 'Seoul, South Korea',
        date: 'March 2020 - March 2021',
        responsibilities: [
            "Increased the Global Students Association scholarship budget by 100 percent. ",
            "Created a system that helped 100+ international students'' find part-time jobs, free training programs and extra-curricular activities while attending university.",
            "Negotiated for a higher quota of international students participation in the HUFS x HUFS Mentoring program organized by the HUFS Alumni Association.",
            "Received a scholarship from the Ministry of Education in recognition of the outstanding support provided to international students during the COVID-19 pandemic."
        ]
    },
    {
        company: "Council on International Students Affairs",
        companyLocation: 'Hankuk University of Foreign Studies',
        position:'International Students’ Representative',
        location: 'Seoul, South Korea',
        date: 'September 2020 - December 2020',
        responsibilities: [
            "Spearheaded the campaign to raise awareness about the 12 main challenges and needs of the 1500+ regular international students at HUFS.",
            "Consolidated the partnership with the HUFS General Student Council, the Dean of International Affairs, the Campus English newspaper, and International Students Organization from other Universities.",
            "Led the process of solving international students’ challenges such as the availability of classes conducted in English, the access to accurate information about University life, and the participation to extracurricular activities on Campus.",
        ]
    },

]


export const cert = [
    {
        type: "language",
        data: [

            {
                name:"Test of Proficiency in Korean (TOPIK)",
                inst:'National Institute for International Education (NIIED)',
                date:'October 2021',
                level: "Level 4",
                link:"https://"
            },
            {
                name:"Test of English for International Communication (TOEIC)",
                inst:'Educational Testing Service (ETS)',
                date:'October 2021',
                score: 915,
                link: "https://"
            },
        ] 
    },
    {
        type: "programming",
        data: [
            {
                name:"CS50’s Web Programming with Python and JavaScript",
                inst:'Harvard University',
                date:'In progress',
                link: "https://"
            },
            {
                name:"Front End Development Libraries Certification",
                inst:'Free Code Camp',
                date:'2022',
                link: "https://"

            },
            {
                name:"JavaScript Algorithms and Data Structures Certification",
                inst:'Free Code Camp',
                date:'2022',
                link: "https://"
            },
            {
                name:"Responsive Web Design Certification",
                inst:'Free Code Camp',
                date:'2022',
                link: "https://"
            },
        ]
    },
    {
        type: "Marketing",
        data:[
            {
                name:'',
                inst: '',
                date:'',
                link:"",
            },
        ]
    },
    {
        type: "Marketing",
        data:[
            {
                name:"Foreign Startup School 2021",
                inst:'InvestSeoul',
                date:'April 2021',
                link:''
            }
        ]
    }
    
]

export const OpenSourceContribution = [
    {
        name: "Zepeto Module Importer",
        description: "A Module that help easily imports different modules in Unity",
        link:"https://github.com/JasperGame/zepeto-modules",
        repo: "https://github.com/JasperGame/zepeto-modules/pulls?q=is%3Apr+is%3Aclosed",
        commit: "https://github.com/JasperGame/zepeto-modules/pull/10",
        work:["Added a looping feature to the Gesture module", "Added the QuickChat Module", "Fixed multiple bugs"]
    },
    {
        name: "Azure Remote Rendering",
        description: "An SDK for the Azure's service that enables you to render highly complex 3D models in real time.",
        link:"https://github.com/Azure/azure-remote-rendering",
        repo: "https://github.com/Azure/azure-remote-rendering/issues/94",
        commit: "https://github.com/Azure/azure-remote-rendering/issues/94",
        work:["Diagnosed a bug and proposed a fix for the conversion of the .FBX to .arrAsset when the json file is parsed"]
    },
    {
        name: "Developer Quiz Site",
        description: "A companion to Learn to Code RPG Game, a visual nove game developed by freeCodeCamp to help people learn how to code.",
        link:"https://developerquiz.org/",
        repo: "https://github.com/freeCodeCamp/Developer_Quiz_Site",
        commit: "https://github.com/freeCodeCamp/Developer_Quiz_Site/commits?author=magmukendi",
        work:["Refactored the code of the Hero section component.", "Fixed a typo in the javascript questions."]
    },
    {
        name: "CyBear Jinni App",
        description: "A mobile application that let the user control the CyBear Jinni Hub a software to control smart devices while keeping user privacy.",
        link: "https://cybearjinni.com/",
        repo: "https://github.com/CyBear-Jinni/cbj_app",
        commit: "https://github.com/CyBear-Jinni/cbj_app/commits/dev?author=magmukendi",
        work:["Provided translation in French from DRCongo"]
    }
]


export const Projects = [
    {
        name: "Wepo’s Mobile App",
        description: "A C2C mobile platform for transaction of seconhand products in DR Congo.",
        technology: "Figma, Javascript and React-Native",
        link:""
    },
    {
        name: "243 Studio Portfolio",
        description: " A web app displaying all the works done by 243 Studio.",
        technology: "HTML, CSS & Vanilla Javascript",
        link:"https://app.243studio.me"
    }, 
    {
        name: "Random Activities",
        description: "A web app that suggests the user what to do as activity for that day.",
        technology: "HTML, CSS, React & Javascript",
        link: homeUrl + "/random-activities/"
    },
    /*{
        name: "Second-hand products Price Calculator",
        description:"A tool that helps the user estimates the value of a  second-products bought in Korean market and to be sold in the Congolese market.",
        technology:"Javascript & React",
        link: homeUrl + "/wepoPriceCalculator"
    },*/
    {
        name: "Tweet Readability",
        description: " A web app that counts the number of characters and words typed by the user in realtime and notifies him before he reaches the limit set by twitter. This web app also assess the readability level of the text.",
        technology:" HTML, CSS, Vanilla Javascript",
        link: homeUrl + "/Tweets-Count/"
    },
    {
        name: "Meme Generator",
        description: "A Simple meme generator that helps the user generate meme and save in their computers in a matter of seconds.",
        technology:" HTML, CSS, Vanilla Javascript",
        link: homeUrl + "/quickymeme/"
    },
    /*{
        name: "Tenzi Game",
        description: " A single player Tenzi game.",
        technology:"HTML, CSS, Javascript & React",
        link: homeUrl + "/Tenzi-Game/"
    },*/
    {
        name:"Piano Notes Player",
        description: "A Single Page Application that allows the user to play piano notes between C3 to C4.",
        technology:"HTML, Javascript & React",
        link:homeUrl + "/pianonotes"
    }
]

export const Awards =
[
    {
        name: "2022 KOICA Inno-Lab B",
        description: "Worked as the Project Manager,  UI/UX designer, market researcher in a team that developed an online platform for reducing unreliable transaction of second-hands products in DR Congo by providing reliable transactions channels for buyers and sellers.",
        date: "June 2022",
        prize: "| 3rd Best Early-Stage Startup",
        org: "Sogang University"

    },
    /*{
        name: "2022 Sogang University 13th Startup Competition",
        date: "April 2022",
        description: "Worked as the Project Manager,  UI/UX designer, market researcher in a team that developed an online platform for reducing unreliable transaction of second-hands products in DR Congo by providing reliable transactions channels for buyers and sellers.",
        prize: "Special Prize",
        org: "Sogang University"

    },
    {
        name: "2021-2 Bring Your Own Ideas",
        description: "Worked in a team to raise awareness about diversity and promoted multicultural programs on Campus.  ",
        date: "December 2021",
        prize: "Prize of Excellence",
        org: "Sogang University"
    },*/
    {
        name: "2021 Africa Startup Idea",
        description: "Worked as the Project Manager,  UI/UX designer, market researcher in a team that developed an online platform for reducing unreliable transaction of second-hands products in DR Congo by providing reliable transactions channels for buyers and sellers.",
        date: "Nov. 2021",
        prize: "TOP 10 best Startup | Seoul, South Korea",
        org: "Korean African Foundation"

    },
]
