import React from 'react'

function Notfound() {
  return (
    <div className="cont">
  <div className="copy-container center-xy">
    <p>
      NO pages to show :).
    </p>

  </div>
</div>

  )
}

export default Notfound