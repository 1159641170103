//import {useState} from "react"
import portfolio from '../assets/img/portfolio.jpg'

/* This is a component to represent each book */


function Studio243(){

    return(
        <div className="hero">
            <div className="hero-intro">
            
            </div>
            <hr/>
            <img alt="studio 243" className="studio243"src={portfolio}/>

        </div>
    )
}


export default Studio243